
import {defineComponent, toRefs} from "vue";
import Loading                      from '@/components/Loading.vue';
import {useBannerSizeAdd}           from "../../logic/banner-size/banner-size-add";

export default defineComponent({
  name      : "BannerSizeCreate",
  components: {
    Loading: Loading,
  },
  setup() {
    const {sizeErrors, nameErrors, createSize, size} = useBannerSizeAdd();

    return {...toRefs(size), sizeErrors, nameErrors, createSize};
  },
  methods: {
    redirectAndCreate() {
      // @ts-ignore
      this.createSize().then((result: boolean) => result && this.$router.push({name: 'sizes'}))
    }
  }
})
