<template>
  <h2>Добавление размера баннера</h2>
  <form @submit.prevent class="uk-form-stacked">
    <div class="uk-margin">
      <label class="uk-form-label">Название</label>
      <input class="uk-input" type="text" v-model="name">
      <div class="" v-if="nameErrors">
        <span class="uk-text-danger" v-for="error in nameErrors">{{error}}</span>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Размер</label>
      <div class="uk-form-controls">
        <input class="uk-input" type="text" v-model="size">
        <div class="" v-if="sizeErrors">
          <span class="uk-text-danger" v-for="error in sizeErrors">{{error}}</span>
        </div>
      </div>
    </div>

    <button class="uk-button uk-button-primary" @click="redirectAndCreate">Сохранить</button>
  </form>
</template>

<script lang="ts">
import {defineComponent, toRefs} from "vue";
import Loading                      from '@/components/Loading.vue';
import {useBannerSizeAdd}           from "../../logic/banner-size/banner-size-add";

export default defineComponent({
  name      : "BannerSizeCreate",
  components: {
    Loading: Loading,
  },
  setup() {
    const {sizeErrors, nameErrors, createSize, size} = useBannerSizeAdd();

    return {...toRefs(size), sizeErrors, nameErrors, createSize};
  },
  methods: {
    redirectAndCreate() {
      // @ts-ignore
      this.createSize().then((result: boolean) => result && this.$router.push({name: 'sizes'}))
    }
  }
})
</script>
