import {ref, reactive}  from 'vue';
import {Api}            from "../../types/api/v1/electrification";
import BannerSizeUrlLoc from "../../services/api/banner-size/BannerSizeUrlLoc";
import BannerSizeAdd = Api.V1.BannerSizeAdd;
import ApiStoreAction   from "../../services/api/ApiStoreAction";


export const useBannerSizeAdd = () => {

    const size = reactive<BannerSizeAdd>({
        size: '',
        name: '',
    });


    const store = new ApiStoreAction<BannerSizeAdd>(new BannerSizeUrlLoc());


    const nameErrors = ref<Array<String>>([]);
    const sizeErrors = ref<Array<String>>([]);

    // @ts-ignore
    const setSize = (value: BannerSizeAdd) => size.value = value;

    const createSize = (): Promise<boolean> => {
        return store.create(size).then((data: BannerSizeAdd) => {
            setSize(data);
            return Promise.resolve(true);
        }, (error) => {
            fetchErrors(error.response.data.errors)
            return Promise.resolve(false);
        });
    }


    const fetchErrors = (errors: { size: Array<string>, name: Array<string> }) => {
        errors.hasOwnProperty('size') && (sizeErrors.value = errors.size);
        errors.hasOwnProperty('name') && (nameErrors.value = errors.name);
    }

    return {nameErrors, sizeErrors, setSize, createSize, size};
};