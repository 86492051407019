import ApiClient                      from "../client/ApiClient";
import {AxiosInstance, AxiosResponse} from "axios";
import UrlLoc                         from "./UrlLoc";

export default class ApiStoreAction<T> {
    private _client: AxiosInstance;

    constructor(private urlLoc: UrlLoc) {
        this._client = ApiClient.init();
    }

    public create(model: T): Promise<T> {
        return this._client.post(this.getCreateUrl(), model).then((response: AxiosResponse<T>) => {
            return response.data;
        })
    }


    protected getCreateUrl(): string {
        return this.urlLoc.createUrl;
    }
}